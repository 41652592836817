/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
 
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
 
@import "../node_modules/@syncfusion/ej2-image-editor/styles/material.css";
@import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';

// INTERFACES / UI

.menu-main {
    --background: #fff;
    --color: white;
}

ion-toolbar {
    --background: #3880FF;
    --color: white;
}

.popTimeSelector {
    --height: 700px !important;
}

.job-search-modal {
    --height: 800px !important;
}
.file-search-modal {
    --height: 90% !important;
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

.job-searchbar-input{
    .searchbar-input-container, .sc-ion-searchbar-ios {
        height: 60px !important;
    }
}

ion-modal {
    color: #333;
}

ion-modal#img-modal {
    --height: 100%;
    --width: 100%;
  }



// User
.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
}

// .payType-pop, .popover-content {
//     min-width: 600px !important;
// }

ion-popover {
    --width: auto;
  }

@media only screen and (max-width: 767px){ 
    .popTimeSelector {
        --height: auto !important;
    }
    
    .showUp-option {
        font-size: 12px !important;
    }
}
